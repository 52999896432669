(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/games-constants/assets/javascripts/games-constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/games-constants/assets/javascripts/games-constants.js');

const constants = {
  GAME_STATUS_OPEN: 'open',
  GAME_BUYIN_CLOSED: 'buyin_closed',
  GAME_STATUS_DRAW_CLOSED: 'draw_closed',
  GAME_STATUS_NOT_ENOUGH_SOLD: 'not_enough_sold',
  GAME_STATUS_SUBMITTABLE_NOT_OPEN: 'submittable_not_open',
  GAME_STATUS_SUBMITTABLE: 'submittable',
  GAME_STATUS_SUBMITTED: 'submitted',
  GAME_STATUS_FINISHED: 'finished',
  GAME_STATUS_PREPARED: 'prepared',
  GAME_STATUS_REFUNDED: 'refunded',
  GAME_STATUS_CANCELLED: 'cancelled',
  GAME_STATUS_SOLD_OUT: 'sold_out',
  MAX_FRACTIONS_PER_GAME: 50,
  MAX_GAMES_TO_DISPLAY_ALL: 1000,
  REDIRECT_TO_MY_GAMES_DELAY: 200
};

if (typeof module !== 'undefined' && module.exports) {
  module.exports = constants;
} else {
  setGlobal('svs.components.marketplaceData.gamesConstants', constants);
}


 })(window);